import React from 'react';
import '../../css/appage.css';
import PhoneViewDropdown from "../PhoneViewDropdown.js";
export default function Categories() {
  return(
  <>
     <PhoneViewDropdown/>
  </>
  );
}
