export const MenuItems = [
	{
		title: "CORE",
		path: "/workstreet/core",
		cName: "dropdown-link",
	},
	{
		title: "CONSULTANCY",
		path: "/workstreet/consultancy",
		cName: "dropdown-link",
	},
	{
		title: "SDE",
		path: "/workstreet/sde",
		cName: "dropdown-link",
	},
	{
		title: "AI-ML",
		path: "/workstreet/ai-ml",
		cName: "dropdown-link",
	},
];
