import React from "react";
import "../../css/appage.css";

export default function Home() {
  return (
    <>
      <h1 className="home">WS</h1>
    </>
  );
}
